import { isClient } from "@vueuse/core";

export function scrollTo(hash: string, c?: any) {
  if (!isClient) return;

  const $el = document.querySelector(`#${hash}`);

  if (!$el) return;

  if (!c) {
    c = {
      behavior: "smooth",
    };
  }

  $el.scrollIntoView(c);
}

export function inputFloor(e: any) {
  if (!e) return;
  e.target.value = Math.floor(Number(e.target.value));
}

export function keydownDigit(e: any) {
  if (!e) return;

  const ASCIICode = e.which ? e.which : e.keyCode;

  if (ASCIICode === 190 || ASCIICode === 188) {
    e.preventDefault();
  }
}
